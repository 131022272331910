<template>
  <v-app data-app>
    <dashboard-core-app-bar v-if="hideAppBar" />

    <dashboard-core-drawer v-if="hideDrawer" />

    <dashboard-core-view />
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),
    computed: {
      hideAppBar () {
        return (this.$route.meta.hideAppBar !== true)
      },
      hideDrawer () {
        return (this.$route.meta.hideDrawer !== true)
      },
    },
  }
</script>
